import { StringStepComponentSpec } from '@assured/step-renderer';

import { StringInputVarietyProps, StringInputVarietyResult } from './types';

const transformToVIN = (value?: string) => {
  if (!value) {
    return '';
  }

  return value
    .replace(/[^A-Za-z0-9]/g, '')
    .toUpperCase()
    .substring(0, 17);
};

type VINMaskProps = {
  value?: string;
  step_component: StringStepComponentSpec;
  updateValue: (field: string | undefined, value: string | null) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const VINMask: React.FC<VINMaskProps> = ({
  step_component,
  value,
  updateValue,
  ...rest
}) => {
  return (
    <input
      type="text"
      value={transformToVIN(value)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      onChange={e => {
        const raw = e.target.value;
        const clean = transformToVIN(raw);
        updateValue(step_component.field, clean);
      }}
    />
  );
};

export const vin = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: StringInputVarietyProps,
): StringInputVarietyResult => {
  return {
    Component: VINMask,
    additionalProps: props,
    narrow: true,
  };
};
